<template>
  <div class="ec">
    lala
    <Datatable
      :value="ecData"
      responsive-layout="scroll"
    >
      <Column
        field="device_id"
        header="ID"
        :sortable="true"
      />
      <Column
        field="reading_time"
        header="Leestijd"
        :sortable="true"
      />
      <Column
        field="battery_voltage"
        header="Accu"
      />
      <Column
        field="EC"
        header="EC"
      />
      <Column
        field="temperature"
        header="Temp"
      />
    </Datatable>
  </div>
</template>

<script>
  import SanitasService from '@/service/SanitasService';

  export default {
    data() {
      return {
        ecData: null,
      };
    },
    created() {
      this.sanitasService = new SanitasService();
    },
    mounted() {
        this.getData()
    },
    methods: {
      getData() {
        this.sanitasService
          .getStatusECs()
          .then((data) => (this.ecData = data));
      },
    },
  };
</script>
